<template>
<div class="col">
  <div class="">
    <div id="while_printing_the_report" class="while_printing_the_report"  v-for="(userDataForPrintTwo, index) in  getPrintGroupData" :key="index">
      <div class="information">
        <div class="part_one">
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.name") }} :</span>
            <span class="when_name">{{ userDataForPrintTwo.name }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("gender") }} :</span>
            <span class="when_name">
              {{
                userDataForPrintTwo.gender == 1
                  ? $t("admin_dashboard_type.male")
                  : $t("admin_dashboard_type.female")
              }}</span
            >
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.age") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.age }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.class_name") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.group_name }}</span>
          </div>
          <div class="parts spans">
            <div
              class="is_span"
              v-if="
                $route.params.exam_type == 0
              "
            >
              <span v-if="$route.params.challenge_no == 0"
                >{{ $t("admin_dashboard_type.pre") }} 1</span
              >
              <span v-if="$route.params.challenge_no == 1"
                >{{ $t("admin_dashboard_type.pre") }} 2</span
              >
              <span v-if="$route.params.challenge_no == 2"
                >{{ $t("admin_dashboard_type.pre") }} 3</span
              >
              <span
                class="border_span"
                :class="{
                  pre:
                    $route.params.exam_type == 0
                }"
              ></span>
            </div>
            <div
              class="is_span ml-4"
              v-if="
                $route.params.exam_type == 1
              "
            >
              <span v-if="$route.params.challenge_no == 0"
                >{{ $t("admin_dashboard_type.post") }} 1</span
              >
              <span v-if="$route.params.challenge_no == 1"
                >{{ $t("admin_dashboard_type.post") }} 2</span
              >
              <span v-if="$route.params.challenge_no == 2"
                >{{ $t("admin_dashboard_type.post") }} 3</span
              >
              <span
                class="border_span"
                :class="{
                  post:
                    $route.params.exam_type == 1
                }"
              ></span>
            </div>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total time") }}:</span>
            <span class="when_name"
              >{{ calculateMinAndSec(userDataForPrintTwo.total_time) }}
            </span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total inputs/time") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.time_count }}</span>
          </div>
        </div>
      </div>
      <!-- Start Images -->
      <div class="is_old_wrapper">
        <div
          class="main_images_checks_container with_english_direction"
          :class="{ with_english_direction: getLocale == 'en' }"
        >
          <div
            class="wrapper_of_images"
            v-for="(item, index) in userDataForPrintTwo.answers"
            :key="index"
          >
            <div class="main_main">
              <div class="wrapper_two">
                <img
                  v-for="(img, i) in item.object"
                  :key="i"
                  :style="{ transform: `rotate(${-img.rotation}deg)` }"
                  class="item"
                  :src="img.img_path"
                  alt="draw image"
                  :class="`custom_img_en_class_${i + 1}`"
                />
              </div>
              <div class="text_images">
                <span>({{ index + 1 }})</span>
                <span>{{ item.answer }}</span>
              </div>
            </div>
            <div class="is_items_with_checkbox">
              <div class="is_is_checker">
                <span>{{ item.negative ? "Negative" : "Negative" }}</span>
                <el-checkbox v-model="item.negative"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.extra_details ? "Extra Details" : "Extra Details"
                }}</span>
                <el-checkbox v-model="item.extra_details"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.diagonal ? "Diagonal" : "Diagonal" }}</span>
                <el-checkbox v-model="item.diagonal"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.asymmetric ? "Asymmetric" : "Asymmetric" }}</span>
                <el-checkbox v-model="item.asymmetric"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.rotated ? "Rotated" : "Rotated" }}</span>
                <el-checkbox v-model="item.rotated"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.uncompleted ? "Uncompleted" : "Uncompleted"
                }}</span>
                <el-checkbox v-model="item.uncompleted"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.with_bg ? "With BG" : "With BG" }}</span>
                <el-checkbox v-model="item.with_bg"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.not_clear ? "Not Clear" : "Not Clear" }}</span>
                <el-checkbox v-model="item.not_clear"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.rejected ? "Rejected" : "Rejected" }}</span>
                <el-checkbox v-model="item.rejected"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.nice_look ? "Nice Look" : "Nice Look" }}</span>
                <el-checkbox v-model="item.nice_look"></el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Images -->
    </div>
  </div>
    <div
      @click="printAllGroup"
      class="sharing_wrapper for_printing_buttons"
      v-if="answersLength > 0"
    >
      <span class="">Print</span>
    </div>
    <div v-if="answersLength == 0" class="no_data shadow-sm">
      No entries
    </div>
</div>
</template>


<script>
export default {
  data() {
    return {
      answersLength: 0,
      objects:  [
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/194",
      "id": 194
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 270,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/194",
      "id": 194
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/2",
      "id": 2
    },
    {
      "rotation": 270,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/2",
      "id": 2
    },
    {
      "rotation": 270,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/2",
      "id": 2
    },
    {
      "rotation": 270,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/197",
      "id": 197
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 270,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/4",
      "id": 4
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    },
    {
      "rotation": 0,
      "img_path": "https:\/\/test.justtolearn.com\/api\/answers\/image\/51",
      "id": 51
    }
  ],

    };
  },
  mounted() {

    this.$store.dispatch("groups/handlerPrintNeomiExamForGroup",
    { exam_type : this.$route.params.exam_type ,
      challenge_no : this.$route.params.challenge_no ,
      group_id : this.$route.params.group_id}).then((res)=>{
        this.answersLength = res.user_data.length;
      })

  },
  computed: {
    // Exam One
    getPrintGroupData() {
      return this.$store.getters["groups/getPrintGroupData"];
    },
    getLocale() {
      return this.$i18n.locale;
    },

  },
  methods: {
    calculateMinAndSec(seconds) {
      if (seconds) {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds}`;
      }
    },
    printAllGroup(){
      window.print()
    }
  },
};
</script>


<style lang='scss' scoped>
@mixin drawImg($left, $top) {
  left: $left;
  top: $top;
  z-index: 99;
}

.no_data{
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 30px;
  font-size: 20px;

  font-weight: bold;
}
.while_printing_the_report {
  background: #fff;
  padding: 10px;
}

.information {
  display: flex;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 20px;
  .when_name {
    margin: 0 5px;
    color: #555;
  }
  .part_one {
    display: flex;
    flex-wrap: wrap;

    .parts {
      display: flex;
      width: 50%;
      margin-bottom: 15px;
      &.spans {
        // display: flex;
        // gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
    }
  }
}

// Start Main Style For Images And Checkboxes
.is_old_wrapper {
  .main_images_checks_container {
    // width: 800px;
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    background: #fff;
    margin: 0 auto;
    // flex-direction: row-reverse;
    // justify-content: center;
    border-bottom: 1px solid #ccc;
    // changes
    //direction: rtl; // will make everything is okay

    // >>>>>>>>>> main container for imgs and checkboxes
    .wrapper_of_images {
      display: flex;
      gap: 13px;
      margin-bottom: 10px;
      // flex-direction: row-reverse;
      // start container for images
      .main_main {
        .wrapper_two {
          width: 200px;
          height: 200px;
          background: #fff;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          .item {
            display: block;
            width: 40px;
            height: 40px;
          }
          // }
        }
        .text_images {
          display: flex;
          justify-content: center;
          gap: 6px;
        }
      }
      // end container for images
      .is_items_with_checkbox {
        .is_is_checker {
          display: flex;
          justify-content: start;
          gap: 8px;
          flex-direction: row-reverse;
        }
      }
    }

    // Start When English - Direction == Ltr
    &.with_english_direction {
      //direction: rtl; // here the problem
      .main_main {
        .wrapper_two {
          width: 200px;
          background: #ddd;
          height: 200px;
          // margin: auto;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .item {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;

            &.custom_img_en_class_1 {
              @include drawImg(0, 0);
            }
            &.custom_img_en_class_2 {
              @include drawImg(40px, 0);
            }
            &.custom_img_en_class_3 {
              @include drawImg(80px, 0);
            }
            &.custom_img_en_class_4 {
              @include drawImg(120px, 0);
            }
            &.custom_img_en_class_5 {
              @include drawImg(160px, 0);
            }
            &.custom_img_en_class_6 {
              @include drawImg(0, 40px);
            }
            &.custom_img_en_class_7 {
              @include drawImg(40px, 40px);
            }
            &.custom_img_en_class_8 {
              @include drawImg(80px, 40px);
            }
            &.custom_img_en_class_9 {
              @include drawImg(120px, 40px);
            }
            &.custom_img_en_class_10 {
              @include drawImg(160px, 40px);
            }
            &.custom_img_en_class_11 {
              @include drawImg(0, 80px);
            }
            &.custom_img_en_class_12 {
              @include drawImg(40px, 80px);
            }
            &.custom_img_en_class_13 {
              @include drawImg(80px, 80px);
            }
            &.custom_img_en_class_14 {
              @include drawImg(120px, 80px);
            }
            &.custom_img_en_class_15 {
              @include drawImg(160px, 80px);
            }
            &.custom_img_en_class_16 {
              @include drawImg(0, 120px);
            }
            &.custom_img_en_class_17 {
              @include drawImg(40px, 120px);
            }
            &.custom_img_en_class_18 {
              @include drawImg(80px, 120px);
            }
            &.custom_img_en_class_19 {
              @include drawImg(120px, 120px);
            }
            &.custom_img_en_class_20 {
              @include drawImg(160px, 120px);
            }
            &.custom_img_en_class_21 {
              @include drawImg(0, 160px);
            }
            &.custom_img_en_class_22 {
              @include drawImg(40px, 160px);
            }
            &.custom_img_en_class_23 {
              @include drawImg(80px, 160px);
            }
            &.custom_img_en_class_24 {
              @include drawImg(120px, 160px);
            }
            &.custom_img_en_class_25 {
              @include drawImg(160px, 160px);
            }
          }
          // }
        }
      }
    }
  }
}
// End Main Style For Images And Checkboxes
.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #eee;
  display: flex;
  margin: 20px auto ;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  min-width: 120px;
    justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
    padding: 0 10px
  }
  .main_images_checks_container{
    gap: 10px;
    border-bottom: none;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;

    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-bottom: 0px;
  }
  .information{
    margin-bottom: 0;
  }

}
</style>


<style lang='scss' >
.while_printing_the_report{
  .el-checkbox:last-of-type{
      margin-bottom: 0;

    }
  @media print {

    .el-checkbox:last-of-type{
      margin-bottom: 0;

    }
  }
}
</style>
